.Setup--cta {
}

.Setup--button {
    background: var(--color-light-purple);
    border-radius: 50px;
    border: 5px solid var(--color-yellow);
    box-shadow: 0 5px 11px #111;
    color: var(--color-yellow);
    cursor: pointer;
    font-family: var(--font-sans);
    font-size: 18pt;
    font-weight: bold;
    margin: 5px;
    padding: 32px;
}
