.Play--container {
    display: flex;
    flex-direction: column;
}

.Play--header {
    font-size: 16pt;
    text-align: center;
    margin-bottom: 1rem;
}

.Play--player-prompt {
    display: flex;
    flex-direction: row;
    font-size: 32pt;
}

.Play--player-prompt-left {
    margin-left: 1rem;
    margin-right: 1rem;
}

.Play--player-prompt-right {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Play--player-prompt label {

}

.Play--player-prompt input {
    margin: 10px;
    font-size: 28pt;
    box-shadow: #6ead22 0 2px 10px;
    border: 2px solid #539304;
}

.Play--player-prompt input:focus {
    box-shadow: #539304 0 2px 10px !important;
    -moz-box-shadow: #539304 0 2px 10px !important;
    -webkit-box-shadow: #539304 0 2px 10px !important;
    outline-offset: 0 !important;
    outline: none !important;
    border: 2px solid #6ead22 !important;
}

.Play--player-prompt button {
    background: var(--color-light-purple);
    border-radius: 50px;
    border: 5px solid var(--color-yellow);
    box-shadow: 0 5px 11px #111;
    color: var(--color-yellow);
    cursor: pointer;
    font-family: var(--font-sans);
    font-size: 18pt;
    font-weight: bold;
    margin: 5px;
    padding: 32px;
    width: 50%;
}

.Play--player-prompt button:disabled {
    background: #c1aac5;
    border-radius: 50px;
    border: 5px solid #f3db80;
    box-shadow: 0 5px 11px #aaa;
    color: #d9d9d9;
    cursor: pointer;
    font-family: var(--font-sans);
    font-size: 18pt;
    font-weight: bold;
    margin: 5px;
    padding: 32px;
}

.Play--question {
    font-size: 18pt;
    font-weight: bold;
}

.Play--question-text {
    color: var(--color-blue);
    margin-bottom: 1rem;
    text-align: center;
}

.Play--video {
    text-align: center;
    margin-bottom: 1rem;
}

.Play--answers {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.Play--answer-item {
    border-radius: 25px;
    box-shadow: 0 5px 11px -7px #111;
    color: white;
    cursor: pointer;
    font-size: 18pt;
    font-weight: bold;
    margin: 5px;
    padding: 32px;
    text-align: center;
}

.Play--answer-item:nth-child(1) {
    background: #9a22ad;
    border: 5px solid #720883;
}

.Play--answer-item:nth-child(2) {
    background: #6ead22;
    border: 5px solid #538a0f;
}

.Play--answer-item:nth-child(3) {
    background: #2253ad;
    border: 5px solid #10316e;
}

.Play--answer-item:nth-child(4) {
    background: #ad2222;
    border: 5px solid #830e0e;
}
