.Leaderboard--container {
    display: flex;
    flex-direction: column;
}

.Leaderboard--content {
    display: flex;
    flex-direction: column;
    font-family: 'Lato', sans-serif;
    font-size: 18pt;
}

.Leaderboard--line {
    background-color: #eeeeee;
    border: 1px solid var(--color-light-purple);
    border-radius: 12px;
    box-shadow: 1px 5px 11px var(--color-light-purple);
    color: var(--color-light-purple);
    display: flex;
    flex-direction: row;
    font-weight: bold;
    margin: 5pt;
    padding: 6pt;
    width: 700px;
}

.Leaderboard--line.Leaderboard--line-place-1,
.Leaderboard--line.Leaderboard--line-place-2,
.Leaderboard--line.Leaderboard--line-place-3 {
    background-color: var(--color-yellow);
}

.Leaderboard--line-place {
    background-repeat: no-repeat;
    background-size: 48px;
    flex-grow: 1;
    padding: 9pt 12pt 12pt 60pt;
    text-align: left;
}

.Leaderboard--line-name {
    flex-grow: 3;
    padding: 9pt 12pt;
}

.Leaderboard--line-score {
    flex-grow: 1;
    padding: 9pt 12pt;
    text-align: right;
}

.Leaderboard--line-place-1 .Leaderboard--line-place {
    background-image: url("../star-gold.png");
}

.Leaderboard--line-place-2 .Leaderboard--line-place {
    background-image: url("../star-purple.png");
}

.Leaderboard--line-place-3 .Leaderboard--line-place {
    background-image: url("../star-blue.png");
}

.Leaderboard--line-extra {
    color: #eeeeee;
}
