:root {
  --color-red: rgb(245, 137, 146);
  --color-teal: rgb(164, 227, 229);
  --color-yellow: rgb(255, 222, 89);
  --color-purple: rgb(53, 57, 154);
  --color-light-purple: rgb(203, 108, 230);
  --color-blue: rgb(0, 148, 212);
  --color-green: rgb(164, 255, 89);
  --color-orange: rgb(255, 139, 89);
  --font-fun: 'Fredoka One', sans-serif;
  --font-sans: 'Lato', sans-serif;
}

.color__blue {
  color: var(--color-blue);
}

.color__purple {
  color: var(--color-light-purple);
}

.font__bold {
  font-weight: bold;
}

.display__flex {
  display: flex;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  background: linear-gradient(white 15%, var(--color-teal));
  color: var(--color-purple);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
