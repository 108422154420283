.ShareableLink {
    background: #eee;
    border-radius: 2px;
    box-shadow: 1px 5px 11px -3px #aaaaaa;
    display: flex;
}

.ShareableLink--link-container {
    flex-grow: 1;
}

.ShareableLink--button-container {
    text-align: right;
}

.ShareableLink--link {
    color: var(--color-blue);
    display: block;
    font-family: var(--font-sans);
    font-weight: bold;
    padding: 1rem;
    text-decoration: none;
    word-break: break-all;
}

.ShareableLink--button {
    background: transparent;
    border: 5px solid transparent;
}