.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20%;
    margin-right: 20%;
}

.App--header {
    margin-bottom: 0.5rem;
}

.App--content {
    margin-left: 2rem;
    margin-right: 2rem;
}

.App--footer {
    margin-top: 12rem;
    margin-bottom: 2rem;
}

.App--footer ul {
    list-style: none;
}

.App--footer ul li {
    display: inline-block;
    margin: 10pt 5pt;
}

.App--footer a {
    text-decoration: none;
    font-weight: bold;
    color: var(--color-purple);
}

.App--footer li + li a:before {
    content: ' | ';
    padding: 1rem;
}
